import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, Box } from "@mui/material";
import { theme } from "./helper/Theme";

import "./App.css";

import ScrollToTop from "./helper/ScrollToTop";
import Loader from "./components/Common/Loader/Loader";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

const Login = React.lazy(() => import('./pages/Login'));
const Signup = React.lazy(() => import('./pages/Signup'));
const PreSale = React.lazy(() => import('./pages/PreSale'));
const Refer = React.lazy(() => import('./pages/Refer'));
const Home = React.lazy( () => import('./pages/Home') );
const Promoter = React.lazy( () => import("./pages/Promoter/Promoter") )
const Termsandcondition = React.lazy( () => import("./pages/teamsAndConditions/index") )
const RedirectPage = React.lazy( () => import("./pages/RedirectWebsite/index") )
const Tier0 = React.lazy( () => import("./pages/Tier0/index") )
const GeneralPromoter = React.lazy( () => import("./pages/GeneralPromoter/index") )

const pages = [
  { name: 'PreSale', path: 'join', element: <PreSale /> },
  { name: 'Refer', path: 'refer', element: <Refer /> },
  { name: 'Home', path: '', element: <Home /> }, // empty path for '/'
  { name: "Template1" , path: "1" , element: <PreSale templateNumber={1} /> },
  { name: "Template2" , path: "2" , element: <PreSale templateNumber={2} /> },
  { name: "Template3" , path: "3" , element: <PreSale templateNumber={3} /> },
  { name: "Template4" , path: "4" , element: <PreSale templateNumber={4} /> },
  { name: "Template5" , path: "5" , element: <PreSale templateNumber={5} /> },
  { name: "Progressbar" , path: "6" , element: <PreSale templateNumber={6} /> },
  { name: "Promoter" , path: "promoter" , element: <Promoter />},
  { name: "Termsandcondition" , path: "terms-and-condition" , element: <Termsandcondition />},
  { name: "RedirectPage" , path: "go" , element: <RedirectPage />},
  { name: "GeneralAffiliate" , path:"general-affiliate" , element: <Tier0 />},
  { name: "GeneralPromoter" , path:"general-promoter" , element: <GeneralPromoter />}



]

// 1. Get projectId
const projectId = "ab4b25ee70e50f539080a1e765e300b8"

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://mainnet.infura.io/v3/f969bb41daaa4bab849eda8fcb4d0f1a'
}

const sepolia = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'ETH',
  explorerUrl: '',
  rpcUrl: 'https://sepolia.infura.io/v3/28c21a32da8a43f6ac4ac47e13a465cc'
}

const hardhatTestNet = {
  chainId: 31337,
  name: 'Hardhat',
  currency: 'ETH',
  explorerUrl: '',
  rpcUrl: 'http://127.0.0.1:8545/'
}

const bnbSmartChain = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed1.binance.org/'
}

const bnbSmartChainTestnet = {
  chainId: 97,
  name: 'BNB Smart Chain Testnet',
  currency: 'BNB',
  explorerUrl: '',
  rpcUrl: 'https://bsc-testnet.infura.io/v3/f969bb41daaa4bab849eda8fcb4d0f1a'
}

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 31337, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  themeVariables: {
    "--w3m-color-mix" : "#0089d1",
    "--w3m-color-mix-strength" : 30 
  },
  customWallets: [
    {
      id: 'myCustomWallet',
      name: `I Don't have a wallet`,
// , // Optional
      // image_url: 'my_custom_wallet_image', // Optional
      mobile_link: 'www.google.com', // Optional - Deeplink or universal //itshows the walletconnectQRcode regardless
      // desktop_link: 'desktop_link', // Optional - Deeplink
      // webapp_link: 'webapp_link', // Optional
      // app_store: 'app_store', // Optional
      // play_store: 'www.google.com' // Optional
    }
  ]
,
  ethersConfig,
  chains: (process.env.REACT_APP_ENVIRONMENT === "production") ? [mainnet , bnbSmartChain] : [hardhatTestNet,sepolia, mainnet , bnbSmartChain, bnbSmartChainTestnet],
  projectId,
  allWallets: "HIDE",
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

function App() {


  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <Box sx={{ bgcolor: 'black.main', color: 'white.main', height: '100%', width: '100vw', minHeight: '100vh', display: 'flex' ,padding:"1rem",paddingTop:{xs : 0  ,sm : "30px"},paddingBottom:{xs : 0  ,sm : "30px"}}}>
            <Suspense fallback={<Loader />} >
              <Routes>
                <Route path="/affiliate" element={<Signup />} />
                {/* <Route path="/signup" element={<Signup />} /> */}
                {pages?.map((item, i) => {
                  const { name, path, element } = item;
                  return (
                    <Route key={i} exact path={`/${path}`} name={name} element={element} />
                  )
                })}
              </Routes>
            </Suspense>
          </Box>
        </ThemeProvider>
      </ScrollToTop>
    </>
  );
}

export default App;
